import { b as bootstrapLazy } from './index-8088fea1.js';
export { s as setNonce } from './index-8088fea1.js';
import { g as globalScripts } from './app-globals-0f993ce5.js';
const defineCustomElements = async (win, options) => {
  if (typeof window === 'undefined') return undefined;
  await globalScripts();
  return bootstrapLazy([["ix-icon", [[1, "ix-icon", {
    "size": [1],
    "color": [1],
    "name": [1],
    "lazyLoading": [4, "lazy-loading"],
    "svgContent": [32],
    "isVisible": [32]
  }, null, {
    "name": ["loadIconContent"]
  }]]]], options);
};
export { defineCustomElements };

